import { Header } from './Header';
import { connect } from 'react-redux';
import { getUserAction } from '../../redux/home/actions';

const mapDispatchToProps = {
  getUserAction,
};

const ReduxHeader = connect(null, mapDispatchToProps)(Header);

export { ReduxHeader as Header };
