const DOS_CLOSE_URL = 'https://nemblack2.com.ua/v1/web';
const DOS_URL = '/v1/web';
// const DOS_CLOSE_URL = 'http://localhost:9071/v1/web';
// const DOS_URL = 'http://localhost:9070/v1/web';

const TARGET_DOS_CLOSE_NAME = 'dosClose';
const TARGET_DOS_NAME = 'dos';

const WEB_KEY_NAME = 'webKey';

/* --не менять название! */
const TARIFF_OPTIMAL_NAME = 'optimal';
const TARIFF_BASIC_NAME = 'basic';
const TARIFF_FULL_NAME = 'full';
const TARIFF_VIP_NAME = 'vip';
/* не менять название!-- */

export {
  TARGET_DOS_CLOSE_NAME,
  TARIFF_OPTIMAL_NAME,
  TARIFF_BASIC_NAME,
  TARIFF_FULL_NAME,
  TARIFF_VIP_NAME,
  TARGET_DOS_NAME,
  DOS_CLOSE_URL,
  WEB_KEY_NAME,
  DOS_URL,
};
