import { makeStyles } from '@material-ui/core/styles';

const loaderStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    backgroundColor: '#fff',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 999,
  },
}));

export { loaderStyles };
