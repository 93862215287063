import { SAVE_PAYMENTS, SAVE_QUERIES, SAVE_USERS } from './actionTypes';

const initialState = {};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_USERS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SAVE_PAYMENTS: {
      const { target, items } = action.payload;

      return {
        ...state,
        [target]: {
          ...state[target],
          payments: items,
        },
      };
    }
    case SAVE_QUERIES: {
      const { target, queries } = action.payload;

      return {
        ...state,
        [target]: {
          ...state[target],
          queries: queries,
        },
      };
    }
    default: {
      return { ...state };
    }
  }
};

export { homeReducer };
