import '../styles.scss';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect, useState } from 'react';
import CachedIcon from '@material-ui/icons/Cached';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import { userViewStyles } from './material-styles';

const headerItems = ['Тип платежа', 'Пополнение(грн.)', 'Реф.ссылка(грн.)', 'Дата'];

const sumPayment = (items) => {
  let sumCash = 0;
  let sumRef = 0;

  items.forEach(({ referral, cash }) => {
    sumCash += cash;
    sumRef += referral;
  });

  return { sumCash, sumRef };
};

const PaymentInfo = ({ setIsPayment, getPayments, isPayment, items }) => {
  const classes = userViewStyles();
  const { sumCash, sumRef } = sumPayment(items);

  const handleClose = () => {
    setIsPayment(false);
  };
  const handleUpdate = () => {
    getPayments();
  };

  return (
    <>
      {isPayment && (
        <div className="payment-info-container">
          <div className="payment-info-tools-container">
            <CachedIcon className={classes.icon} color="primary" onClick={handleUpdate} />
            <CloseIcon className={classes.icon} color="secondary" onClick={handleClose} />
          </div>
          <div className="title3">Общая сумма пополнения: {sumCash} грн.</div>
          <div className="title3">Общая сумма за реф. ссылку: {sumRef} грн.</div>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {headerItems.map((name, i) => (
                    <TableCell key={i} align={i ? 'left' : 'center'}>
                      {name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map(({ updatedAt, card_type, referral, cash }, i) => (
                  <TableRow key={i}>
                    <TableCell component="th" scope="row">
                      {card_type}
                    </TableCell>
                    <TableCell align="center">{cash}</TableCell>
                    <TableCell align="center">{referral}</TableCell>
                    <TableCell align="center">{updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </>
  );
};

export { PaymentInfo };
