import '../styles.scss';
import React from 'react';
import { UserInfo } from './UserInfo';
import { TARGET_DOS_NAME } from '../../../common/constants';

const UserView = ({
  translateUserAction,
  getPaymentsAction,
  getQueriesAction,
  updateUserAction,
  target,
  title,
  user,
}) => {
  const IS_DOS = target === TARGET_DOS_NAME;
  const targetClassName = IS_DOS ? 'inner-dos' : 'inner-buro';

  if (user === null || user === false) {
    return (
      <div className={`user-view-inner ${targetClassName}`}>
        <div className="title">{title}</div>
        <div className="title3">Пользователь не найден</div>
      </div>
    );
  }

  if (user === undefined) {
    return (
      <div className={`user-view-inner ${targetClassName}`}>
        <div className="title">{title}</div>
      </div>
    );
  }

  return (
    <div className={`user-view-inner ${targetClassName}`}>
      <div className="title">{title}</div>
      <UserInfo
        translateUserAction={translateUserAction}
        getPaymentsAction={getPaymentsAction}
        getQueriesAction={getQueriesAction}
        updateUserAction={updateUserAction}
        target={target}
        user={user}
      />
    </div>
  );
};

export { UserView };
