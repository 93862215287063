import { makeStyles } from '@material-ui/core/styles';

const userViewStyles = makeStyles(() => ({
  input: {
    paddingLeft: 5,
  },
  select: {
    paddingLeft: 5,
  },
  textField: {
    marginTop: 10,
    width: '100%',
  },
  icon: {
    marginRight: 20,
  },
  btn: {
    ['@media (max-width: 450px)']: { marginTop: 20 },
  },
}));

export { userViewStyles };
