import thunk from 'redux-thunk';
import { rootReducer } from './root-reducer';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

const configureStore = () => {
  const enhancer = composeWithDevTools(applyMiddleware(thunk));
  const store = createStore(rootReducer, enhancer);
  return store;
};

export { configureStore };
