import axios from 'axios';
import { store } from '../App';
import { errorToast } from '../utils';
import { Redirect } from 'react-router-dom';
import { startLoadingAction, stopLoadingAction } from '../redux/settings/actions';

/* Usage:
 * const res = HttpService.get('/users');
 * const response = await HttpService.parseResponse(res);
 */

class HttpService {
  async request(url, method, params, body) {
    const { dispatch } = store;

    const config = {
      url,
      withCredentials: true,
      method: method,
      data: body,
      headers: {
        'Content-Type': 'application/json',
        ...params,
      },
    };

    dispatch(startLoadingAction());
    return axios(config);
  }

  get({ url, params = {} }) {
    return this.request(url, 'GET', params);
  }
  post({ url, params = {}, body }) {
    return this.request(url, 'POST', params, body);
  }
  remove({ url, params = {} }) {
    return this.request(url, 'DELETE', params);
  }

  async parseResponse(request) {
    const { dispatch } = store;

    try {
      const res = await request;

      return res.data;
    } catch (err) {
      errorToast(err.message);
      return null;
    } finally {
      dispatch(stopLoadingAction());
    }
  }
}

const httpService = new HttpService();

export { httpService };
