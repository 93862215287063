import { Loader } from './Loader';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  isLoading: state.settings.isLoading,
});

const ReduxLoader = connect(mapStateToProps, null)(Loader);

export { ReduxLoader as Loader };
