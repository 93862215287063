import * as localStorage from '../common/local-store';
import React, { useEffect } from 'react';
import { Login, Home } from '../view';
import { checkWebKey } from '../helpers/auth';
import { WEB_KEY_NAME } from '../common/constants';
import { Redirect, Switch, Route, useHistory } from 'react-router-dom';

const checkStorWebKey = (history) => {
  const storWebKey = localStorage.get(WEB_KEY_NAME);

  if (storWebKey) {
    return checkWebKey(storWebKey);
  } else {
    history.push('/');
  }
};

const Router = () => {
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      const success = await checkStorWebKey(history);

      if (success) {
        history.push('/home');
      } else {
        history.push('/');
      }
    };
    fetchData();
  }, [history]);

  return (
    <Switch>
      <Route exact path="/" children={<Login />} />
      <Route exact path="/home" children={<Home />} />
      <Route path="*" render={() => <Redirect to="/" />} />
    </Switch>
  );
};

export { Router };
