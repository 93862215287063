import { makeStyles } from '@material-ui/core/styles';

const loginStyles = makeStyles(() => ({
  input: {
    marginTop: 30,
    width: '90%',
  },
  button: {
    marginTop: 30,
  },
}));

export { loginStyles };
