import { combineReducers } from 'redux';
import { homeReducer } from './home/reducer';
import { settingsReducer } from './settings/reducer';

const rootReducer = combineReducers({
  settings: settingsReducer,
  home: homeReducer,
});

export { rootReducer };
