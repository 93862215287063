import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { loaderStyles } from './material-styles';

const Loader = ({ isLoading }) => {
  const classes = loaderStyles();

  return (
    <>
      {isLoading && (
        <div className={classes.root}>
          <CircularProgress size={200} color="secondary" />
        </div>
      )}
    </>
  );
};

export { Loader };
