import '../styles.scss';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import CachedIcon from '@material-ui/icons/Cached';
import { userViewStyles } from './material-styles';

const QueryInfo = ({ setIsQueries, getQueries, isQueries, queries }) => {
  const classes = userViewStyles();
  const handleClose = () => {
    setIsQueries(false);
  };
  const handleUpdate = () => {
    getQueries();
  };

  return (
    <>
      {isQueries && (
        <div className="query-info-container">
          <div className="query-info-tools-container">
            <CachedIcon className={classes.icon} color="primary" onClick={handleUpdate} />
            <CloseIcon className={classes.icon} color="secondary" onClick={handleClose} />
          </div>

          <div className="title3">Статистика запросов</div>
          <div className="query-info-text">Дата последнего запроса: {queries.updatedAt}</div>
          {/* <div className="query-info-text">
            Сумма успешных запросов(базовый): {queries.successful_queries_basic}
          </div>
          <div className="query-info-text">
            Сумма не успешных запросов(базовый):{' '}
            {queries.unsuccessful_queries_basic}
          </div> */}

          <div className="query-info-text">
            Сумма успешных запросов(полный): {queries.successful_queries_full}
          </div>
          <div className="query-info-text">
            Сумма не успешных запросов(полный): {queries.unsuccessful_queries_full}
          </div>

          {/* <div className="query-info-text">
            Сумма успешных запросов(оптимальный):{' '}
            {queries.successful_queries_optimal}
          </div>
          <div className="query-info-text">
            Сумма не успешных запросов(оптимальный):{' '}
            {queries.unsuccessful_queries_optimal}
          </div> */}

          <div className="query-info-text">
            Сумма успешных запросов(vip): {queries.successful_queries_vip}
          </div>
          <div className="query-info-text">
            Сумма не успешных запросов(vip): {queries.unsuccessful_queries_vip}
          </div>
        </div>
      )}
    </>
  );
};

export { QueryInfo };
