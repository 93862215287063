import './styles.scss';
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router-dom';
import { loginStyles } from './material-styles';
import { errorToast } from '../../utils/notifications';
import { checkWebKey, doAuth } from '../../helpers/auth';
import { ConfirmLoginModal } from './confirm-login-modal';

const Login = () => {
  const classes = loginStyles();
  const [openModal, setOpenModal] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const history = useHistory();

  const handleInput = ({ target: { value, name } }) => {
    switch (name) {
      case 'username': {
        setUsername(value);
        break;
      }
      case 'password': {
        setPassword(value);
        break;
      }
    }
  };

  const login = async () => {
    if (!username || !password) {
      return errorToast('All fields are require!');
    }

    const data = { username: username.trim(), password: password.trim() };
    const success = await doAuth(data);

    if (success) {
      setUsername('');
      setPassword('');

      setOpenModal(true);
    }
  };

  const confirmLogin = async (value) => {
    const success = await checkWebKey(value);
    success && history.push('/home');
  };

  return (
    <div className="center-container">
      <ConfirmLoginModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        confirmLogin={confirmLogin}
      />

      <div className="center-inner">
        <div className="input-inner">
          <TextField
            className={classes.input}
            label="Username"
            name="username"
            value={username}
            onChange={handleInput}
          />

          <TextField
            className={classes.input}
            label="Password"
            name="password"
            type="password"
            value={password}
            onChange={handleInput}
          />

          <Button className={classes.button} variant="contained" color="primary" onClick={login}>
            Login
          </Button>
        </div>
      </div>
    </div>
  );
};

export { Login };
