import { Home } from './Home';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  dosClose: state.home.dosClose,
  dos: state.home.dos,
});

const mapDispatchToProps = {};

const ReduxHome = connect(mapStateToProps, mapDispatchToProps)(Home);

export { ReduxHome as Home };
