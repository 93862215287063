import './styles.scss';
import React from 'react';
import { UserView } from './user-view';

const Home = ({ dosClose, dos }) => {
  return (
    <div className="home-container">
      <UserView user={dos} title="Черный" target="dos" />
      <UserView user={dosClose} title="Черный закрытый" target="dosClose" />
    </div>
  );
};

export { Home };
