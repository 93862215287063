import '../styles.scss';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import React, { useReducer, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import NativeSelect from '@material-ui/core/NativeSelect';
import { QueryInfo } from './QueryInfo';
import { PaymentInfo } from './PaymentInfo';
import { TranslateUser } from './TranslateUser';
import { userViewStyles } from './material-styles';
import {
  TARIFF_OPTIMAL_NAME,
  TARIFF_BASIC_NAME,
  TARIFF_FULL_NAME,
  TARIFF_VIP_NAME,
  TARGET_DOS_NAME,
} from '../../../common/constants';

const getTariffName = (name) => {
  switch (name) {
    case TARIFF_OPTIMAL_NAME: {
      return 'Оптимальный';
    }
    case TARIFF_BASIC_NAME: {
      return 'Базовый';
    }
    case TARIFF_FULL_NAME: {
      return 'Полный';
    }
    case TARIFF_VIP_NAME: {
      return 'vip';
    }
    default: {
      return 'Полный';
    }
  }
};
const setDefaultValue = (value) => (value === null ? '' : value);
const setBinaryValue = (value) => {
  if (
    value === TARIFF_OPTIMAL_NAME ||
    value === TARIFF_BASIC_NAME ||
    value === TARIFF_FULL_NAME ||
    value === TARIFF_VIP_NAME
  ) {
    value = getTariffName(value);
  }

  const setValue = value === 1 ? 'Да' : 'Нет';
  if (value === 1 || value === 0) {
    return setValue;
  }

  return value;
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_VALUE': {
      const { value, name, key } = action.payload;
      return {
        ...state,
        [key]: { ...state[key], [name]: value },
      };
    }
    case 'RESET_VALUE': {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return { ...state };
    }
  }
}

const UserInfo = ({
  translateUserAction,
  getPaymentsAction,
  getQueriesAction,
  updateUserAction,
  user,
  target,
}) => {
  const {
    translated_user,
    internal_name,
    telegram_id,
    first_name,
    last_name,
    updatedAt,
    createdAt,
    payments,
    username,
    is_admin,
    queries,
    tariff,
    access,
    id,
  } = user;
  const { count_requests, used_requests, valid_until, userId, cash, name } = tariff;
  const initialState = {
    user: {
      translated_user,
      internal_name,
      first_name,
      last_name,
      username,
      is_admin,
      access,
    },
    tariff: {
      count_requests,
      used_requests,
      valid_until,
      cash,
      name,
    },
  };
  const classes = userViewStyles();
  const [isTranslateUser, setIsTranslateUser] = useState(false);
  const [isPayment, setIsPayment] = useState(false);
  const [isQueries, setIsQueries] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);

  const IS_DOS = target === TARGET_DOS_NAME;

  const handleInput = ({ target }) => {
    const { value, name } = target;
    const key = target.getAttribute('data');
    let transformedValue = '';

    if (value.trim() !== '') {
      transformedValue = isNaN(value) ? value : Number(value);
    }

    dispatch({
      type: 'SET_VALUE',
      payload: { value: transformedValue, name, key },
    });
  };
  const handleDate = ({ target: { value: date } }) => {
    const value = moment(date).format('YYYY-MM-DD HH:mm:ss');
    const name = 'valid_until';
    const key = 'tariff';

    dispatch({ type: 'SET_VALUE', payload: { value, name, key } });
  };

  const handleEdit = () => {
    dispatch({ type: 'RESET_VALUE', payload: { ...initialState } });
    setEditUser(true);
  };
  const handleCancel = () => {
    dispatch({ type: 'RESET_VALUE', payload: { ...initialState } });
    setEditUser(false);
  };
  const handleSave = () => {
    const { user, tariff } = state;
    const data = {
      userData: { ...user, telegram_id, id },
      tariffData: { ...tariff, userId: id },
    };

    updateUserAction(data, target);
    setEditUser(false);
    // --закрываем окна статистики
    setIsPayment(false);
    setIsQueries(false);
  };

  /* tools */
  const getTranslateUserView = () => {
    setIsTranslateUser(true);
  };
  const translatedUser = (message) => {
    translateUserAction({
      telegramUserId: telegram_id,
      message,
    });
  };

  const getPayments = () => {
    setIsPayment(true);
    getPaymentsAction({ userId: id, target });
  };
  const getQueries = () => {
    setIsQueries(true);
    getQueriesAction({ userId: id, target });
  };
  /* tools */

  const setInput = ({ valueName, dataKey, title, value }) => {
    return (
      <>
        <div className="text-wrapper">{title}: </div>
        {editUser ? (
          <TextField
            className={classes.input}
            inputProps={{ data: dataKey }}
            name={valueName}
            value={setDefaultValue(state[dataKey][valueName])}
            size="small"
            onChange={handleInput}
          />
        ) : (
          setDefaultValue(value)
        )}
      </>
    );
  };

  const setSelect = ({ valueName, dataKey, title, value, items }) => {
    return (
      <>
        <div className="text-wrapper">{title}: </div>
        {editUser ? (
          <NativeSelect
            className={classes.select}
            inputProps={{ data: dataKey }}
            name={valueName}
            value={state[dataKey][valueName]}
            onChange={handleInput}
          >
            {items.map((option, i) => (
              <option key={i} value={option.value}>
                {option.title}
              </option>
            ))}
          </NativeSelect>
        ) : (
          setBinaryValue(value)
        )}
      </>
    );
  };

  return (
    <>
      <div className="title3">Пользователь:</div>
      <div className="user-info-wrapper">
        <div className="text-wrapper">telegram id: </div>
        {setDefaultValue(telegram_id)}
      </div>
      <div className="user-info-wrapper">
        {setInput({
          valueName: 'first_name',
          dataKey: 'user',
          title: 'Первое имя в telegram',
          value: first_name,
        })}
      </div>
      <div className="user-info-wrapper">
        {setInput({
          valueName: 'last_name',
          dataKey: 'user',
          title: 'Второе имя в telegram',
          value: last_name,
        })}
      </div>
      <div className="user-info-wrapper">
        {setInput({
          valueName: 'username',
          dataKey: 'user',
          title: 'Ник в telegram',
          value: username,
        })}
      </div>
      <div className="user-info-wrapper">
        {setInput({
          valueName: 'internal_name',
          dataKey: 'user',
          title: 'Внутреннее имя',
          value: internal_name,
        })}
      </div>
      <div className="user-info-wrapper">
        {setSelect({
          valueName: 'translated_user',
          dataKey: 'user',
          title: 'Пользователь переведен в защищенный бот',
          value: translated_user,
          items: [
            { value: 1, title: 'Да' },
            { value: 0, title: 'Нет' },
          ],
        })}
      </div>
      <div className="user-info-wrapper">
        {setSelect({
          valueName: 'is_admin',
          dataKey: 'user',
          title: 'Пользователь является администратором',
          value: is_admin,
          items: [
            { value: 1, title: 'Да' },
            { value: 0, title: 'Нет' },
          ],
        })}
      </div>
      <div className="user-info-wrapper">
        {setSelect({
          valueName: 'access',
          dataKey: 'user',
          title: 'Пользователь имеет доступ к боту',
          value: access,
          items: [
            { value: 1, title: 'Да' },
            { value: 0, title: 'Нет' },
          ],
        })}
      </div>

      <div className="title3">Тариф пользователя:</div>
      <div className="user-info-wrapper">
        {setSelect({
          valueName: 'name',
          dataKey: 'tariff',
          title: 'Выбранный тариф',
          value: name,
          items: [
            { value: TARIFF_FULL_NAME, title: 'Полный' },
            { value: TARIFF_VIP_NAME, title: 'VIP' },
          ],
        })}
      </div>
      <div className="user-info-wrapper">
        {setInput({
          valueName: 'count_requests',
          dataKey: 'tariff',
          title: 'Баланс запросов',
          value: count_requests,
        })}
      </div>
      <div className="user-info-wrapper">
        {setInput({
          valueName: 'used_requests',
          dataKey: 'tariff',
          title: 'Использованно запросов',
          value: used_requests,
        })}
      </div>
      <div className="user-info-wrapper">
        {setInput({
          valueName: 'cash',
          dataKey: 'tariff',
          title: 'Колличество средств на счете(грн)',
          value: cash,
        })}
      </div>
      <div className="user-info-wrapper">
        <div className="text-wrapper"> Тариф действителен до: </div>
        {editUser ? (
          <TextField
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            defaultValue={moment(state.tariff.valid_until).format('YYYY-MM-DD')}
            className="user-info-date-picker"
            size="small"
            onChange={handleDate}
          />
        ) : (
          moment(valid_until).format('DD.MM.YYYY')
        )}
      </div>

      <div className="user-info-button-wrapper">
        <Button
          className={classes.btn}
          variant="contained"
          color="primary"
          onClick={handleEdit}
          disabled={editUser}
        >
          Редактировать
        </Button>

        <Button
          className={classes.btn}
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={!editUser}
        >
          Coхранить
        </Button>

        <Button
          className={classes.btn}
          variant="contained"
          color="secondary"
          onClick={handleCancel}
          disabled={!editUser}
        >
          Отменить
        </Button>
      </div>

      <div className="user-info-button-tools-wrapper">
        <Button
          className={classes.btn}
          disabled={isPayment}
          variant="contained"
          color="primary"
          onClick={getPayments}
        >
          Платежи
        </Button>

        <Button
          className={classes.btn}
          disabled={isQueries}
          variant="contained"
          color="primary"
          onClick={getQueries}
        >
          Запросы
        </Button>

        {IS_DOS && (
          <Button
            className={classes.btn}
            disabled={isTranslateUser}
            variant="contained"
            color="primary"
            onClick={getTranslateUserView}
          >
            Перевести в close
          </Button>
        )}
      </div>

      {IS_DOS && (
        <TranslateUser
          isTranslateUser={isTranslateUser}
          setIsTranslateUser={setIsTranslateUser}
          translatedUser={translatedUser}
        />
      )}

      <PaymentInfo
        setIsPayment={setIsPayment}
        getPayments={getPayments}
        isPayment={isPayment}
        items={payments}
      />

      <QueryInfo
        setIsQueries={setIsQueries}
        getQueries={getQueries}
        isQueries={isQueries}
        queries={queries}
      />
    </>
  );
};

export { UserInfo };
