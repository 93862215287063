import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';
import { doLogout } from '../../helpers/auth';
import { useHistory } from 'react-router-dom';
import { headerStyles } from './material-styles';
import { errorToast } from '../../utils/notifications';

const Header = ({ getUserAction }) => {
  const history = useHistory();
  const classes = headerStyles();
  const [searchValue, setSearchValue] = useState('');

  const logout = async () => {
    const success = await doLogout();
    success && history.push('/');
  };

  const handleSearch = ({ target: { value } }) => {
    setSearchValue(value);
  };

  const onKeyPressHandler = ({ charCode }) => {
    if (charCode === 13) {
      if (!searchValue.trim()) {
        return errorToast('This field is require!');
      }
      getUserAction(searchValue.trim());
    }
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          {/* <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
            >
              <MenuIcon />
            </IconButton> */}
          <Typography className={classes.title} variant="h6" noWrap>
            Admin panel
          </Typography>

          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Введите telegram id"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              onChange={handleSearch}
              onKeyPress={onKeyPressHandler}
              value={searchValue}
            />
          </div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Button className={classes.logoutButton} variant="outlined" onClick={logout}>
              LogOut
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export { Header };
