import * as localStorage from '../../common/local-store';
import queryString from 'query-string';
import { checkWebKey } from '../../helpers/auth';
import { successToast, errorToast } from '../../utils';
import { httpService } from '../../services/HttpService';
import { SAVE_PAYMENTS, SAVE_QUERIES, SAVE_USERS } from './actionTypes';
import {
  TARGET_DOS_CLOSE_NAME,
  TARGET_DOS_NAME,
  DOS_CLOSE_URL,
  WEB_KEY_NAME,
  DOS_URL,
} from '../../common/constants';

const URL_ITEMS = [
  { targetUrl: DOS_URL, target: TARGET_DOS_NAME },
  { targetUrl: DOS_CLOSE_URL, target: TARGET_DOS_CLOSE_NAME },
];

const getTargetUrl = (target) => {
  const urlData = URL_ITEMS.find((el) => el.target === target);

  return urlData ? urlData.targetUrl : '';
};

const getUsersData = async (targetUrl, data) => {
  const url = setQueryParams(`${targetUrl}/user`, data);
  const res = httpService.get({ url });
  const user = await httpService.parseResponse(res);

  return user;
};

const setQueryParams = (url, { ...params }) => {
  const urlParams = queryString.stringify({ ...params });
  return `${url}?${urlParams}`;
};

const getWebKey = async () => {
  const webKey = localStorage.get(WEB_KEY_NAME);

  const success = await checkWebKey(webKey);
  if (!success) {
    errorToast('You need to reload the page');
    return false;
  }

  return webKey;
};

const getAllUserInfo = async ({ telegramUserId, webKey }) => {
  const userData = {};

  for (const i in URL_ITEMS) {
    const { targetUrl, target } = URL_ITEMS[i];

    const user = await getUsersData(targetUrl, { telegramUserId, webKey });
    if (user) {
      userData[target] = { ...user, payments: [], queries: {} };
    } else {
      /* если ошибка -> null */
      userData[target] = user;
    }
  }

  return userData;
};

// ============================================

const getUserAction = (telegramUserId) => async (dispatch) => {
  const webKey = await getWebKey();
  if (!webKey) return;

  const userData = await getAllUserInfo({ telegramUserId, webKey });
  dispatch({ type: SAVE_USERS, payload: userData });
};

const updateUserAction = (data, target) => async (dispatch) => {
  const webKey = await getWebKey();
  if (!webKey) return;

  const targetUrl = getTargetUrl(target);
  const res = httpService.post({
    url: `${targetUrl}/user`,
    body: { ...data, webKey },
  });
  const updatedUser = await httpService.parseResponse(res);
  const userData = { [target]: { ...updatedUser, payments: [], queries: {} } };
  dispatch({ type: SAVE_USERS, payload: userData });
};

const getPaymentsAction =
  ({ userId, target }) =>
  async (dispatch) => {
    const webKey = await getWebKey();
    if (!webKey) return;

    const targetUrl = getTargetUrl(target);
    const url = setQueryParams(`${targetUrl}/payments`, { userId, webKey });
    const res = httpService.get({ url });
    const items = await httpService.parseResponse(res);

    dispatch({ type: SAVE_PAYMENTS, payload: { target, items } });
  };

const getQueriesAction =
  ({ userId, target }) =>
  async (dispatch) => {
    const webKey = await getWebKey();
    if (!webKey) return;

    const targetUrl = getTargetUrl(target);
    const url = setQueryParams(`${targetUrl}/queries`, { userId, webKey });
    const res = httpService.get({ url });
    const queries = await httpService.parseResponse(res);

    dispatch({ type: SAVE_QUERIES, payload: { target, queries } });
  };

const translateUserAction =
  ({ telegramUserId, message }) =>
  async (dispatch) => {
    const webKey = await getWebKey();
    if (!webKey) return;

    const res = httpService.post({
      url: `${DOS_URL}/translate-user`,
      body: { telegramUserId, message },
    });
    const success = await httpService.parseResponse(res);

    if (success) {
      const userData = await getAllUserInfo({ telegramUserId, webKey });
      dispatch({ type: SAVE_USERS, payload: userData });

      return successToast('User translated successful!');
    }

    return errorToast('Error: User did not translate!');
  };

export {
  translateUserAction,
  updateUserAction,
  getPaymentsAction,
  getQueriesAction,
  getUserAction,
};
