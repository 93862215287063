import * as localStorage from '../common/local-store';
import { errorToast, successToast } from '../utils';
import { httpService } from '../services/HttpService';
import { WEB_KEY_NAME, DOS_URL } from '../common/constants';

const doAuth = async ({ ...data }) => {
  const res = httpService.post({ url: `${DOS_URL}/auth`, body: data });
  const response = await httpService.parseResponse(res);
  response?.success && successToast(response?.message);
  !response?.success && errorToast(response?.message);
  return response?.success;
};

const checkWebKey = async (value) => {
  const res = httpService.post({
    url: `${DOS_URL}/check-web-key`,
    body: { webKey: value },
  });
  const { success, webKey } = await httpService.parseResponse(res);

  if (success) {
    localStorage.set(WEB_KEY_NAME, webKey);
  }

  return success;
};

const doLogout = async () => {
  const res = httpService.get({ url: `${DOS_URL}/auth` });
  const { success } = await httpService.parseResponse(res);

  if (success) {
    localStorage.remove(WEB_KEY_NAME);
  }

  return success;
};

export { checkWebKey, doLogout, doAuth };
