import { toast } from 'react-toastify';

const options = {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 4000,
  hideProgressBar: false,
  pauseOnHover: true,
};

const successToast = (text) =>
  toast.success(text, {
    ...options,
    type: toast.TYPE.SUCCESS,
    className: 'success-toast',
  });

const infoToast = (text) =>
  toast.info(text, {
    ...options,
    type: toast.TYPE.INFO,
  });

const errorToast = (text) =>
  toast.error(text, {
    ...options,
    type: toast.TYPE.ERROR,
    className: 'error-toast',
  });

const defaultToast = (text) => toast(text, options);

export { successToast, defaultToast, errorToast, infoToast };
