import { makeStyles } from '@material-ui/core/styles';

const confirmLoginStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 300,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  input: {
    marginTop: 30,
    width: '90%',
  },
  button: {
    marginTop: 30,
  },
}));

export { confirmLoginStyles };
