import React from 'react';
import { Header } from '../header';
import { useRouteMatch } from 'react-router-dom';

const Layout = ({ children }) => {
  const { isExact } = useRouteMatch({
    path: '/',
    strict: true,
    sensitive: true,
  });

  return (
    <>
      {!isExact && <Header />}
      <main>{children}</main>
    </>
  );
};

export { Layout };
