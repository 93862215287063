import './styles.scss';
import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { confirmLoginStyles } from './material-styles';

const ConfirmLoginModal = ({ confirmLogin, setOpenModal, openModal }) => {
  const classes = confirmLoginStyles();
  const [webKey, setWebKey] = useState('');

  const handleClose = () => {
    setOpenModal(false);
    setWebKey('');
  };

  const handleInput = ({ target: { value } }) => {
    setWebKey(value);
  };

  const confirm = async () => {
    const webKeyTrimmed = webKey.trim();
    if (!webKeyTrimmed) return;

    setWebKey('');
    confirmLogin(webKeyTrimmed);
  };

  return (
    <>
      <Modal open={openModal} onClose={handleClose}>
        <div className={classes.paper}>
          <div className="input-inner">
            <TextField
              className={classes.input}
              label="Token"
              value={webKey}
              onChange={handleInput}
            />

            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={confirm}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export { ConfirmLoginModal };
