import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import { Layout } from './layout';
import { Router } from './router';
import { Loader } from './components';
import { Provider } from 'react-redux';
import { configureStore } from './redux';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';

export const store = configureStore();

const App = () => {
  return (
    <>
      <Provider store={store}>
        <Loader />
        <BrowserRouter>
          <Layout>
            <Router />
          </Layout>
        </BrowserRouter>
      </Provider>
      <ToastContainer />
    </>
  );
};

export default App;
