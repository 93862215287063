import { UserView } from './UserView';
import { connect } from 'react-redux';
import {
  translateUserAction,
  getPaymentsAction,
  updateUserAction,
  getQueriesAction,
} from '../../../redux/home/actions';

const mapDispatchToProps = {
  translateUserAction,
  getPaymentsAction,
  updateUserAction,
  getQueriesAction,
};

const ReduxUserView = connect(null, mapDispatchToProps)(UserView);

export { ReduxUserView as UserView };
